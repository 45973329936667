import React from 'react';

const POSTS_PER_PAGE = 7;

export const PostsContext = React.createContext({
  cursor: 1,
  posts: [],
  hasMore: true,
  loadMore: () => {},
});

export const PostsProvider = ({ children }) => {
  const [cursor, setCursor] = React.useState(1);
  const [posts, setPosts] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);

  React.useEffect(() => {
    const initPosts = () => {
      if (posts.length === 0) {
        fetchPosts(1).then(res => {
          res.length < POSTS_PER_PAGE && setHasMore(false);
          setCursor(2);
          setPosts(prevPosts => prevPosts.concat(res));
        });
      }
    };
    initPosts();
  }, []);

  const fetchPosts = pageNumber =>
    fetch(`${__PATH_PREFIX__}/posts-data/index${pageNumber}.json`).then(res =>
      res.json()
    );

  const loadMore = () => {
    const pageNum = cursor;
    setCursor(prevCursor => prevCursor + 1);
    fetchPosts(pageNum)
      .then(res => {
        if (res.length > 0) {
          setPosts(prevPosts => prevPosts.concat(res));
        }
      })
      .catch(() => setHasMore(false));
  };

  return (
    <PostsContext.Provider value={{ posts, loadMore, hasMore, cursor }}>
      {children}
    </PostsContext.Provider>
  );
};
